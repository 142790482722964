import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Dropdown,
  Input,
  Layout,
  Menu,
  message,
  Modal,
  Progress,
  Tooltip
} from 'antd';
import {
  MenuOutlined,
  UserOutlined,
  WechatOutlined,
  SearchOutlined,
  ClearOutlined,
  SyncOutlined
} from '@ant-design/icons';

import moment from 'moment';
import { AuthContext } from '../contexts/auth.context';
const { Header } = Layout;
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../hooks/useWindowDimension';
import { getUserData } from '../utils/auth.utils';
import { setLocalStorage } from '../utils/storage.utils';
import { USER_INFO } from '../constants/config';
import { CustomModal } from '../components/Common/CustomModal';
import { SearchForMenuModal } from '../components/Common/CustomModal/SearchForMenuModal';
import MenuDB from '../store/localstorage/MenuDB';
import { IMenuList } from '../services/settings/types';

import { useGlobalContext } from '@/contexts/global.context';
import {
  syncUnitsList,
  syncUserList,
  syncAgentsList,
  syncFinancialYears,
  syncLocationsList,
  syncMenuItems,
  syncProductsList,
  syncRoutesList,
  syncVehicleList,
  syncGlobalSettings,
  syncReportList
} from './sync.services';
import NotificationV2 from './notification/v2';
import { checkAccess } from '@/routes/acl';
import { createEmptyVoidPromise } from '@/utils/createEmptyPromise';
import { useQuery } from '@tanstack/react-query';
import { get_hr_self_calendar_data } from '@/services/hr/queries';
import { check_in_out_attendance_mutation } from '@/services/hr/mutations';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import getErrorMessage from '@/utils/getError';
import useBroadcast from '@/hooks/useBroadcast';
import { hrEventColors } from '@/pages/hr/calendar/constant';
import isAxiosError from '@/utils/isAxiosError';
import { useFilterStore } from '@/store/zustand';
import {
  useFinancialYearPersistHydration,
  useFinancialYearStore
} from '@/store/zustand/financial-year';
import LatestFeatureShowCase from '@/components/LatestFeature';
import { deleteUserFilter } from '@/services/users/services.users';
import ResetFilterModal, { ResetFilterModalProps } from '@/components/FliterTable/ResetFilterModal';
import eventEmitter from '@/utils/events';

interface IHeaderWrapperProps {
  // eslint-disable-next-line no-unused-vars
  collapsed: boolean;
  showHamburger: boolean;
  setshowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  refetchMenu: () => void;
  isSecondaryMenu?: boolean;
  showSubMenuHamburger?: boolean;
}

const NotificationsComponent = React.memo(() => {
  return <NotificationV2 />;
});

NotificationsComponent.displayName = 'NotificationComponent';

const HeaderWrapper = ({
  showHamburger,
  setshowDrawer,
  collapsed,
  refetchMenu,
  isSecondaryMenu = false,
  showSubMenuHamburger
}: IHeaderWrapperProps) => {
  const { profileData, signOut } = useContext(AuthContext);
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const navigate = useNavigate();
  const [searchMenuItems, setSearchMenuItems] = useState<IMenuList[]>([]);

  const [isClearModalOpen, setIsClearModalOpen] = useState(false);

  const { attendanceStatus, handleAttendance } = useBroadcast();
  const [isCheckModal, setIsCheckModal] = useState(false);
  const { setShowSecondaryDrawer, showInfo } = useGlobalContext();

  const [percentcomplete, setPercentComplete] = useState(0);
  const [isModalOpentwo, setIsModalOpentwo] = useState(false);
  const [isModalOpenSearchMenu, setIsModalOpenSearchMenu] = useState(false);

  const [currentStatus, setCurrentStatus] = useState<'holiday' | 'leave' | 'shift'>();
  const eventColor = currentStatus ? hrEventColors[currentStatus] : null;

  const zustandFilter = useFilterStore();
  const { getFinancialYears, financialYearList, syncToday } = useFinancialYearStore();
  const isHydrated = useFinancialYearPersistHydration();

  // Fetch financial years from IndexedDB and populate the store
  useEffect(() => {
    if (!isHydrated) return;
    financialYearList.length === 0 ? getFinancialYears() : syncToday();
  }, [isHydrated]);

  const { isLoading } = useQuery(['today-hr_details'], async () => {
    const startDate = moment().startOf('day').toISOString();
    const endDate = moment().endOf('day').toISOString();

    const params = new URLSearchParams({ startDate, endDate, archiveStatus: 'ACTIVE' });
    const response = await get_hr_self_calendar_data(params.toString());

    if (response.holidays.length > 0) {
      setCurrentStatus('holiday');
    } else if (response.leaves.length > 0) {
      setCurrentStatus('leave');
    } else if (response.shifts.length > 0) {
      setCurrentStatus('shift');
    }

    // Handle Attendance Status
    if (response.attendances.length === 0) {
      handleAttendance('out');
      return;
    }

    const attendance = response.attendances[0];
    handleAttendance(attendance.endDate ? 'out' : 'in');
  });

  async function checkAttendance(type: 'in' | 'out') {
    try {
      setIsCheckModal(false);
      setLoading(true);
      await check_in_out_attendance_mutation(type);
      handleAttendance(type);
    } catch (error) {
      if (isAxiosError(error)) return;
      CustomErrorModal({ message: getErrorMessage(error) });
    } finally {
      setLoading(false);
    }
  }

  const syncData = async () => {
    const syncGlobalSettingsFunc = () =>
      syncGlobalSettings().then((data) => {
        if (!data) return;

        let loggedInUser = getUserData();
        loggedInUser = { ...loggedInUser, global: data };
        setLocalStorage(USER_INFO, loggedInUser);
      });

    setIsModalOpentwo(true);
    if (percentcomplete == 0) {
      const syncOperations = [
        {
          name: 'Users',
          func: syncUserList,
          increment: 10,
          hasPermission: checkAccess('READ_USER')
        },
        {
          name: 'Units',
          func: syncUnitsList,
          increment: 10,
          hasPermission: checkAccess('READ_PRODUCT')
        },
        {
          name: 'Products',
          func: syncProductsList,
          increment: 5,
          hasPermission: checkAccess('READ_PRODUCT')
        },
        {
          name: 'Locations',
          func: syncLocationsList,
          increment: 10,
          hasPermission: checkAccess('READ_LOCATION')
        },
        {
          name: 'Routes',
          func: syncRoutesList,
          increment: 10,
          hasPermission: checkAccess('READ_ROUTE')
        },
        {
          name: 'Financial Years',
          func: syncFinancialYears,
          increment: 10,
          hasPermission: checkAccess('READ_ACCOUNT')
        },
        {
          name: 'Agents',
          func: syncAgentsList,
          increment: 10,
          hasPermission: checkAccess('READ_USER')
        },
        {
          name: 'Vehicle',
          func: syncVehicleList,
          increment: 10,
          hasPermission: checkAccess('READ_VEHICLE')
        },
        {
          name: 'Menu Items',
          func: syncMenuItems,
          increment: 10,
          hasPermission: true
        },
        {
          name: 'Global Settings',
          func: syncGlobalSettingsFunc,
          increment: 5,
          hasPermission: true
        },
        {
          name: 'Reports',
          func: syncReportList,
          increment: 10,
          hasPermission: true
        }
      ];

      const promises = syncOperations.map(async ({ name, hasPermission, ...props }) => {
        const baseFunction = hasPermission ? props.func : createEmptyVoidPromise;
        return baseFunction()
          .then(() => ({ name, status: 'fulfilled' }))
          .catch(() => ({ name, status: 'rejected' }))
          .finally(() => setPercentComplete((prev) => prev + props.increment));
      });

      const results = await Promise.all(promises);
      const failedOperations = results
        .filter((result) => result.status === 'rejected')
        .map((result) => result.name);

      if (failedOperations.length > 0) {
        const failedNames = failedOperations.join(', ');
        message.error(`Failed to sync: ${failedNames}!`);
      }

      refetchMenu();

      // const expenseCategoryList = await get_expense_category_list('skip=0&count=1000');
      // // console.log(expenseCategoryList);
      // if (expenseCategoryList) {
      //   ExpenseCategoryDB.add(expenseCategoryList);
      //   setPercentComplete((prev) => prev + 10);
      // }
    } else {
      return;
    }
  };

  async function onResetAllFilter() {
    try {
      setIsResetting(true);
      await deleteUserFilter();
      message.success('Filter reset successfully.');
    } catch (error: unknown) {
      if (isAxiosError(error)) return;
      CustomErrorModal({
        title: 'Error',
        message: getErrorMessage(error) || 'Error reseting filter'
      });
    } finally {
      setIsResetting(false);
    }
  }

  async function clearLocalData() {
    setIsClearModalOpen(false);
    await zustandFilter.resetAll();
    navigate(0);
  }

  const RightNavMenu = (
    <Menu
      items={[
        {
          key: 'sign-out',
          icon: <UserOutlined />,
          label: 'Sign Out',
          onClick: signOut
        },
        {
          key: 'sync',
          icon: <SyncOutlined />,
          label: 'Sync Data',
          onClick: syncData
        },
        {
          key: 'clear-local-data',
          icon: <ClearOutlined />,
          label: 'Clear Filter',
          onClick: () => setIsClearModalOpen(true)
        }
      ]}
    />
  );

  const handleCanceltwo = () => {
    setPercentComplete(0);
    setIsModalOpentwo(false);
  };

  const handleSearchMenu = async () => {
    const menuItems = await MenuDB.getAllMenuItem();
    setSearchMenuItems(menuItems);
    setIsModalOpenSearchMenu(true);
  };

  const onResetFilter: ResetFilterModalProps['onReset'] = async (type, scope) => {
    try {
      setIsClearModalOpen(false);
      if (type === 'local') {
        clearLocalData();
        return;
      }

      if (scope === 'all') {
        await onResetAllFilter();
        eventEmitter.emit('RESET_CURRENT_PAGE_SAVED_FILTERS', { reset: 'all' });
        return;
      }

      eventEmitter.emit('RESET_CURRENT_PAGE_SAVED_FILTERS', { reset: 'current' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Header
      className={`site-layout-background ${showHamburger ? '!px-4 md:!px-8' : ''}`}
      style={{
        position: 'fixed',
        margin: '0px',
        zIndex: 1000,
        width:
          showHamburger || showInfo
            ? '100vw'
            : collapsed
            ? `${width - 75 + 5}px`
            : `${width - 220 + 5}px`,
        transition: 'all 0.1s linear',
        backgroundColor: eventColor?.light,
        borderBottom: eventColor ? `2px solid ${eventColor.dark}` : 'none'
      }}>
      <ResetFilterModal
        isLoading={isResetting}
        visible={isClearModalOpen}
        onReset={onResetFilter}
        onCancel={() => setIsClearModalOpen(false)}
      />

      <Modal
        title="Attendance"
        visible={isCheckModal}
        width="500px"
        onOk={() => checkAttendance(attendanceStatus === 'in' ? 'out' : 'in')}
        onCancel={() => setIsCheckModal(false)}>
        <p>Are you sure you want to check {attendanceStatus === 'in' ? 'out' : 'in'}?</p>
      </Modal>
      <CustomModal
        title={'Syncing Data...!'}
        isModalOpen={isModalOpentwo}
        setIsModalOpen={setIsModalOpentwo}
        handleCancel={handleCanceltwo}
        width={'40%'}
        footer={false}>
        <div className="flex flex-col justify-center items-center">
          <Progress type="circle" percent={percentcomplete} />
          {percentcomplete == 100 ? (
            <div className="text-xl font-bold" style={{ color: '#58be23' }}>
              Completed!
            </div>
          ) : (
            <div className="text-xl font-bold" style={{ color: '#1890ff' }}>
              Loading...
            </div>
          )}
        </div>
      </CustomModal>
      <CustomModal
        title={''}
        isModalOpen={isModalOpenSearchMenu}
        setIsModalOpen={setIsModalOpenSearchMenu}
        style={{ overflowX: 'auto' }}
        width={'50%'}
        footer={false}>
        <SearchForMenuModal
          searchMenuItems={searchMenuItems}
          handleItemClick={() => setIsModalOpenSearchMenu(false)}
        />
      </CustomModal>
      <LatestFeatureShowCase />
      <div
        className={`w-full h-full ${
          showHamburger || showInfo ? 'flex justify-between' : 'flex justify-end'
        } items-center`}>
        <div className="flex items-center gap-4 relative">
          {isSecondaryMenu && (showSubMenuHamburger || showInfo) && (
            <Button
              type="primary"
              className="rounded-sm"
              onClick={() => setShowSecondaryDrawer((prev) => !prev)}>
              <MenuOutlined className="scale-125" />
            </Button>
          )}

          {(showHamburger || showInfo) && (
            <div>
              <MenuOutlined className="scale-125" onClick={() => setshowDrawer((prev) => !prev)} />
            </div>
          )}
        </div>
        <div className="flex justify-center items-center gap-5">
          <div onClick={handleSearchMenu}>
            {width < 900 ? (
              <Tooltip title="search">
                <Button shape="circle" icon={<SearchOutlined />} />
              </Tooltip>
            ) : (
              <Input
                placeholder="Search for Menu..."
                prefix={<SearchOutlined className="site-form-item-icon" />}
                allowClear={true}
                readOnly={true}
              />
            )}
          </div>
          <Button
            loading={loading || isLoading}
            onClick={() => setIsCheckModal(true)}
            type={attendanceStatus === 'in' ? 'default' : 'primary'}
            danger={attendanceStatus === 'in'}>
            {attendanceStatus === 'in' ? 'Check Out' : 'Check In'}
          </Button>

          <Tooltip title="Create Channel" color="green">
            <WechatOutlined
              onClick={() => navigate('/channel/new')}
              style={{ transform: 'scale(1.4)' }}
            />
          </Tooltip>
          <NotificationsComponent />
          <Dropdown overlay={RightNavMenu} trigger={['click']}>
            <div className="cursor-pointer">
              {!showHamburger && <span className="mr-5">{profileData?.name}</span>}
              <Avatar icon={<UserOutlined />} />
              {/* <div>Sync</div> */}
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default HeaderWrapper;
