import jwtDecode from 'jwt-decode';
import {
  USER_INFO,
  JWT_TOKEN,
  LAST_FEATURE_SHOWCASE,
  JWT_REFRESH_TOKEN
} from '../constants/config';
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage,
  clearLocalSession
} from './storage.utils';

export const getUserDetailsFromToken = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    if (decoded) {
      setLocalStorage(USER_INFO, decoded);
      return decoded;
    }
  } catch (e) {
    return false;
  }
};

export const getToken = () => {
  if (getLocalStorage(JWT_TOKEN)) {
    return getLocalStorage(JWT_TOKEN);
  }
};

export const isAuthenticated = () => {
  return !!getToken();
  // return true;
};

export const getUserData = () => {
  const userData = getLocalStorage(USER_INFO);
  if (!userData || !userData.perms) {
    if (!window.location.pathname.includes('#/login')) window.location.href = '#/login';
  } else {
    return userData;
  }
};

export const logout = () => {
  clearLocalStorage(JWT_TOKEN);
  clearLocalStorage(USER_INFO);
  clearLocalSession(JWT_TOKEN);
  clearLocalSession(USER_INFO);
  clearLocalStorage(LAST_FEATURE_SHOWCASE);
  clearLocalStorage(JWT_REFRESH_TOKEN);
};

export const globalSignOut = () => {
  const channel = new BroadcastChannel('auth-channel');
  channel.postMessage({ action: 'signout' });
  channel.close();
};

export const isTokenExpired = (token: string) => {
  try {
    const { exp } = jwtDecode<{ userId: number; iat: number; exp: number }>(token);
    const currentInSeconds = Math.floor(Date.now() / 1000);
    return exp <= currentInSeconds + 60 * 30; // 30 minutes
  } catch (error) {
    return true;
  }
};
