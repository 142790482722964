import useCache from '@/hooks/useCache';
import useIgnoreGlobalTab from '@/hooks/useIgnoreGlobalTab';
import { get_all_price_groups, get_price_by_groupId } from '@/services/products/queries';
import { ICreatePriceGroup } from '@/services/products/types';
import { get_woo_all_products_list } from '@/services/woocommerce/queries';
import { IProductRow } from '@/services/woocommerce/types';
import getErrorMessage from '@/utils/getError';
import { useEffect, useState } from 'react';
import { formatCreatedProductToGrid } from '@/services/woocommerce/services';
import { message, PageHeader, Select } from 'antd';
import Grid from './Grid';
import { useNavigate } from 'react-router-dom';

interface Props {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function UpdateProductQtyPrice({ setIsLoading }: Props) {
  const [rows, setRows] = useState<IProductRow[]>([]);
  const { get: getPriceGroupDetails } = useCache(get_price_by_groupId);

  const navigate = useNavigate();

  useIgnoreGlobalTab();

  const [priceGroups, setPriceGroups] = useState<ICreatePriceGroup[]>([]);

  async function onPriceGroupChange(priceGroupId: number) {
    try {
      setIsLoading(true);
      if (!priceGroupId) return;
      const currentPrice = await getPriceGroupDetails(priceGroupId);

      setRows((prevRows) => {
        return prevRows.map((row) => {
          if (row.id && row.id > 0) {
            const price = currentPrice.find(
              (p) => p.productId === row.id && p.unitId === row.unitId
            );

            row.sell_price = price?.sellingPrice || 0;
          }

          return row;
        });
      });
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchProductAndPriceGroups() {
    try {
      setIsLoading(true);
      const [priceGroups, wooProducts] = await Promise.all([
        get_all_price_groups(),
        get_woo_all_products_list()
      ]);

      setPriceGroups(priceGroups.results);
      const { rows } = await formatCreatedProductToGrid(wooProducts);
      setRows(rows);

      if (rows.length === 0) {
        message.error('Please add product to woocommerce first.');
        navigate('/woo-commerce/products/new');
      }
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchProductAndPriceGroups();
  }, []);

  return (
    <div>
      <div className="flex flex-col gap-2">
        <label htmlFor="priceGroups">Select Price Groups</label>
        <Select
          id="priceGroups"
          placeholder="Select price group"
          dropdownMatchSelectWidth={false}
          showSearch
          className="max-w-md"
          optionFilterProp="label"
          optionLabelProp="label"
          onChange={(value: number) => onPriceGroupChange(value)}
          options={priceGroups.map((price) => ({ label: price.name, value: price.id }))}
        />
      </div>

      <div className="mt-2 space-y-2">
        <PageHeader title="Qty and Price" style={{ padding: '8px 0' }} />
        <Grid
          rows={rows}
          // Dispatch SetActions
          setRows={setRows}
          setIsLoading={setIsLoading}
        />
      </div>
    </div>
  );
}

export default UpdateProductQtyPrice;
