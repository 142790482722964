import { useQuery } from '@tanstack/react-query';
import { Button, PageHeader, Spin, Table, Divider, message } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import type { ColumnsType } from 'antd/es/table';
import AppContent from '@/components/Common/Content/Content';
import {
  get_adjustment_details,
  get_adjustment_line_details,
  get_reasons_list
} from '@/services/adjustments/queries';

import ReuseChannel from '../../channel/Reuse';
import { checkAccess } from '@/routes/acl';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { IAdjustmentInvoice } from '@/services/invoice/types';
import { getAdjustmentPrintData } from '@/components/Common/InvoicePrint/AdjustmentInvoice/services';
import { CustomModal } from '@/components/Common/CustomModal';
import AdjustmentInvoice from '@/components/Common/InvoicePrint/AdjustmentInvoice/AdjustmentInvoice';
import InvoicePrintButton from '@/components/Common/InvoicePrintButton/InvoicePrintButton';
import { getLocationByDetail, getProductById, getUnit, getUser } from '@/services';
import { ICreateInvoiceResponse } from '@/services/settings/types';
import { ILocations } from '@/services/locations/types';
import { IAdjustmentLines, IAdjustmentList } from '@/services/adjustments/types';

interface AdjustmentResuableViewProps {
  id: number;
}
const AdjustmentResuableView: React.FC<AdjustmentResuableViewProps> = ({ id }) => {
  const navigate = useNavigate();
  const [locationDetails, setLocationDetails] = useState<ILocations>();
  const [adjustmentDetails, setAdjustmentDetails] = useState<IAdjustmentList>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [lines, setLines] = useState<IAdjustmentLines[]>([]);
  const [invoiceData, setInvoiceData] = useState<IAdjustmentInvoice>(Object);
  const [openModalForInvoicePrint, setOpenModalForInvoicePrint] = useState<boolean>(false);

  const { refetch } = useQuery(['adjustment-view', id], async () => {
    const response = await get_adjustment_details(id);
    const reasons = await get_reasons_list();

    if (response?.data) {
      const locD = await getLocationByDetail(response.data.locationId);
      const reason = reasons?.data?.find((curr) => curr.id == response.data.reasonId);
      const linesResponse = await get_adjustment_line_details(response.data.status, id);

      if (linesResponse.data.length > 0) {
        const allProductIds = linesResponse.data.map((d) => d.productId);
        const uniqueProductIds = Array.from(new Set(allProductIds));
        await ProductsDB.addProductsIfAbsent(uniqueProductIds);

        for (let index = 0; index < linesResponse.data.length; index++) {
          const line = linesResponse.data[index];
          const product = await getProductById(line.productId);
          const unit = await getUnit(line.unitId);

          line.productName = product.name;
          line.quantity = `${line.quantity} ${unit.shortName}`;
        }
      }

      if (response.data.addedBy) {
        const user = await getUser(response.data.addedBy);
        response.data.createdByName = user.name;
      }

      if (response.data.updatedBy) {
        const user = await getUser(response.data.updatedBy);
        response.data.updatedByName = user.name;
      }
      setLines(linesResponse.data);
      setAdjustmentDetails({ ...response.data, reasonName: reason?.name });
      setLocationDetails(locD);
      setIsloading(false);
    }
    return response;
  });

  const breadcrumbItems = [
    {
      label: 'Adjustments',
      link: '/adjustments'
    },
    {
      label: 'Edit'
    }
  ];

  const columns: ColumnsType<IAdjustmentLines> = [
    {
      title: 'S.N',
      key: 'sn',
      width: 10,
      render: (a, b, c) => <div>{c + 1}.</div>
    },
    {
      title: 'Product',
      dataIndex: 'productName',
      render: (text: number) => <a>{text}</a>
    },
    {
      title: 'Lot & Expiry',
      dataIndex: 'lotExpiryDuration',
      render: (text: string) => <a>{text}</a>
    },

    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (text: number) => <a>{text}</a>
    },
    {
      title: 'Subtotal',
      dataIndex: 'totalAmount',
      render: (text: number) => <a>{text}</a>
    }
  ];

  const generateInvoiceForPrint = async (curr: ICreateInvoiceResponse) => {
    if (!adjustmentDetails) {
      return message.error('Failed to generate invoice for print.');
    }

    setIsloading(true);
    const invoiceModalData = await getAdjustmentPrintData(
      adjustmentDetails.id,
      adjustmentDetails.status,
      adjustmentDetails.reason,
      adjustmentDetails.locationId,
      curr
    );

    setIsloading(false);
    setInvoiceData(invoiceModalData);
    setOpenModalForInvoicePrint(true);
  };

  const handleInvoicePrintModalClose = async () => {
    setOpenModalForInvoicePrint(false);
    refetch();
  };

  return (
    <div>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <Spin spinning={isLoading}>
          <CustomModal
            footer={false}
            isModalOpen={openModalForInvoicePrint}
            setIsModalOpen={setOpenModalForInvoicePrint}
            title="Adjustment Invoice Print">
            <AdjustmentInvoice
              adjustmentDetails={invoiceData.adjustmentDetails}
              lines={invoiceData.lines}
              locationDetails={invoiceData.locationDetails}
              invoiceLayouts={invoiceData.invoiceLayouts}
              handleModalClose={handleInvoicePrintModalClose}
            />
          </CustomModal>
          <PageHeader title="Adjustment Information" style={{ padding: '4px 0px' }} />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
            <div>
              <PageHeader subTitle="Location" style={{ padding: '8px 0px' }} />
              {locationDetails ? (
                <>
                  <div style={{ color: 'black' }}>
                    {locationDetails?.name ? locationDetails.name : ''}
                    {`${locationDetails?.zip ? `${locationDetails.zip},` : ''} ${
                      locationDetails?.address ? `${locationDetails.address},` : ''
                    } ${locationDetails?.city ? `${locationDetails.city},` : ''} ${
                      locationDetails?.country ? `${locationDetails.country}` : ''
                    }`}
                    {`${locationDetails?.phone ? `${locationDetails.phone},` : ''} ${
                      locationDetails?.email ? `${locationDetails.email}` : ''
                    }`}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div>
              <PageHeader subTitle="Details" style={{ padding: '8px 0px' }} />
              {adjustmentDetails ? (
                <div style={{ color: 'black' }}>
                  <span className="block">
                    Financial Reference : {adjustmentDetails.financialReference}
                  </span>
                  <span className="block">Reason: {adjustmentDetails.reasonName}</span>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div>
              <PageHeader subTitle="Created Details" style={{ padding: '8px 0px' }} />
              {adjustmentDetails ? (
                <div style={{ color: 'black' }}>
                  <span className="block">Created By : {adjustmentDetails?.createdByName}</span>
                  <span className="block">
                    Created At: {adjustmentDetails.createdAt.substr(0, 10)}
                  </span>
                  <span className="block">Updated By : {adjustmentDetails?.updatedByName}</span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <Table columns={columns} dataSource={lines || []} rowKey={'id'} />

          <div className="flex justify-end  gap-5 mt-5">
            <div
              style={{
                width: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <InvoicePrintButton
                getInvoice={(curr) => {
                  generateInvoiceForPrint(curr);
                }}
              />
            </div>
            <Button type="default" onClick={() => navigate('/adjustments')}>
              Back
            </Button>
          </div>
        </Spin>
        <Divider />
        {checkAccess('READ_CHANNEL') && <ReuseChannel slug={`adjustments_${id}`} />}
      </AppContent>
    </div>
  );
};

export default AdjustmentResuableView;
