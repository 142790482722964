import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Input,
  message,
  Table,
  Tooltip,
  Menu,
  Form,
  Select,
  TableProps,
  Spin,
  Tag
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useLocation, useNavigate } from 'react-router-dom';
import AppContent from '@/components/Common/Content';

import {
  get_product_active_lots,
  get_product_list_all_status,
  get_units_list
} from '@/services/products/queries';
import UnitsDB from '@/store/localstorage/UnitsDB';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { IUnits, IProductType, IProductListTableData } from '@/services/products/types';

import DiscussionChatModal from '@/components/Common/DiscussionChatModal';
import CustomizeTable from '@/components/Common/CustomizeTable/CustomizeTable';
import TableFilter from '@/components/FliterTable';
import moment from 'moment';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { ICategory } from '@/services/category/types';
import CategoryDB from '@/store/localstorage/CategoryDB';
import { get_category_list } from '@/services/category/queries';
import { checkAccess } from '@/routes/acl';
import { ConvertObjectToURL } from '@/utils/converturl';

import { SorterResult } from 'antd/lib/table/interface';
import { getUserData } from '@/utils/auth.utils';
import ActionDropdown from '@/components/Common/Dropdownactions';
import TableCell from '@/components/Common/CustomizeTable/CustomCell';
import { useReactToPrint } from 'react-to-print';
import { CustomModal } from '@/components/Common/CustomModal';
import CustomTable, {
  IColumnDataForCustomTable
} from '@/components/Common/CustomResuableInvoice/CustomTable';
import { convertLocalToUTCString } from '@/utils/convertToUTC';
import CustomUpdateIcon from '@/components/Common/CustomIcons/CustomUpdateIcon';
import { exportExcelAutoWidth } from '@/utils/exportExcelAutoWidth';
import LocationSearchV2 from '@/components/Common/CustomSearch/Location';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import HideConfirm from '@/components/Common/HideConfirm';
import {
  hide_product_by_location,
  update_products_hidden_mutation
} from '@/services/products/mutations';
import { checkProductLocationHidden } from '@/services/products/services';
import { getLocationByDetail } from '@/services';
import CustomErrorModal from '@/components/Common/CustomErrorModal';
import { useFilterStore } from '@/store/zustand';
import { ListPage } from '@/constants/list.enum';

const List: React.FC = () => {
  const printPDFRef = useRef<any>();
  const navigate = useNavigate();
  const [allProducts, setAllProducts] = useState<IProductListTableData>({ total: 0, results: [] });
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [isloading, setIsloading] = useState(true);
  const [unitsList, setUnitsList] = useState<IUnits[]>([]);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [openmodalforExport, setOpenModalforexport] = useState<boolean>(false);

  const [location, setLocation] = useState<{ id: number; name: string }>();
  const currentPageLocation = useLocation();
  const zustandFilter = useFilterStore();

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<any>);
  };
  const { preferences } = getUserData();
  const preferenceLocationId = preferences?.preferences
    ? JSON.parse(preferences?.preferences)?.locationId
    : undefined;

  async function checkProductLot(product: IProductType) {
    const productId = parseInt(product.id as string);
    const currentActiveLots = await get_product_active_lots([productId]);
    const productLots = await Promise.all(
      currentActiveLots.map(async (lot) => {
        const location = await getLocationByDetail(lot.locationId);
        return { ...lot, locationName: location.name };
      })
    );

    // Show Error, that product has active lots
    if (productLots.length > 0) {
      CustomErrorModal({
        width: 600,
        message: (
          <div>
            <p>
              Product <strong>{product.name}</strong> has active lots so it can't be hidden
              globally.
            </p>
            <Table
              columns={[
                { title: 'Location', dataIndex: 'locationName', key: 'locationName' },
                { title: 'Lot Number', dataIndex: 'lotNumber', key: 'lotNumber' },
                { title: 'Grade', dataIndex: 'grade', key: 'grade' },
                { title: 'Available (qty.)', dataIndex: 'qtyAvailable', key: 'qtyAvailable' }
              ]}
              dataSource={productLots}
              pagination={false}
            />
          </div>
        )
      });
    }

    return { hasLots: productLots.length > 0 };
  }

  async function onHideProduct(record: IProductType) {
    try {
      setIsloading(true);
      const productId = parseInt(record.id as string);
      const currentHidden = location
        ? checkProductLocationHidden(record, location.id)
        : record.hidden;

      if (!location && !currentHidden) {
        const { hasLots } = await checkProductLot(record);
        if (hasLots) return;
      }

      location
        ? await hide_product_by_location({
            locationId: location.id,
            productIds: [productId],
            hidden: !currentHidden
          })
        : await update_products_hidden_mutation(record.id, !currentHidden);

      message.success(
        `Product ${record.name} has been ${!currentHidden ? 'archived' : 'unarchived'}.`
      );

      const updatedProductLists = allProducts.results.map((product) => {
        if (product.id === productId) {
          if (location) {
            const status = product.status.find((s) => s.locationId === location.id);
            if (status) status.hidden = !currentHidden;
          } else {
            product.hidden = !currentHidden;
          }
        }

        return product;
      });

      setAllProducts((prev) => ({ ...prev, results: updatedProductLists }));

      // Only Get Product which are active
      const activeProductLists = updatedProductLists.filter((product) => {
        if (product.id === productId) {
          if (location) {
            const status = product.status.find((s) => s.locationId === location.id);
            return !status?.hidden;
          } else {
            return !product.hidden;
          }
        }

        return true;
      });

      zustandFilter.updateState(currentPageLocation.pathname, {
        data: { total: allProducts.total, results: activeProductLists }
      });

      zustandFilter.removeData(ListPage.ARCHIVE_PRODUCTS);
      await ProductsDB.addProducts(updatedProductLists);
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  }

  const columns: ColumnsType<any> = [
    Table.SELECTION_COLUMN,
    // Table.EXPAND_COLUMN,
    {
      title: 'S.N',
      key: 'SN',
      width: 10,
      sorter: (a, b) => a.id - b.id,
      sortOrder: sortedInfo.columnKey === 'id' ? sortedInfo.order : null,
      render: (text, record: IProductType, index: number) => {
        return <TableCell>{(page - 1) * size + (index + 1)}</TableCell>;
      }
    },
    {
      title: 'Name',
      key: 'name',
      width: 50,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        return (
          // <Link to={`/products/${record.id}`} color="black">
          <TableCell>{record.name}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Default Unit',
      dataIndex: 'defaultUnit',
      key: 'defaultUnit',
      width: 15,
      sorter: (a, b) => a.defaultUnit.localeCompare(b.defaultUnit),
      sortOrder: sortedInfo.columnKey === 'defaultUnit' ? sortedInfo.order : null
    },

    {
      title: 'SKU',
      key: 'sku',
      width: 15,
      sorter: (a, b) => a.sku.localeCompare(b.sku),
      sortOrder: sortedInfo.columnKey === 'sku' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        return (
          // <Link to={`/products/${record.id}`} color="black">
          <TableCell>{record.sku}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'Lot Expiry Duration',
      key: 'lotExpiryDuration',
      width: 15,
      sorter: (a, b) => a.lotExpiryDuration - b.lotExpiryDuration,
      sortOrder: sortedInfo.columnKey === 'lotExpiryDuration' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        return (
          // <Link to={`/products/${record.id}`} color="black">
          <TableCell>{record.lotExpiryDuration}</TableCell>
          // </Link>
        );
      }
    },
    {
      title: 'VAT %',
      key: 'vat',
      width: 15,
      sorter: (a, b) => a.vat - b.vat,
      sortOrder: sortedInfo.columnKey === 'vat' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        return <TableCell>{record.vat?.toFixed(2)}</TableCell>;
      }
    },
    {
      title: 'Global Status',
      key: 'globalStatus',
      width: 14,
      sorter: (a, b) => a.hidden - b.hidden,
      sortOrder: sortedInfo.columnKey === 'globalStatus' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        const isHidden = record.hidden;
        return <Tag color={isHidden ? 'red' : 'green'}>{isHidden ? 'Inactive' : 'Active'}</Tag>;
      }
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 10,
      fixed: 'right',
      render: (record: IProductType) => {
        const menuItems: (
          | { key: string; label: JSX.Element; onClick?: undefined }
          | { key: string; label: JSX.Element; onClick: () => void }
        )[] = [];
        if (checkAccess('UPDATE_PRODUCT')) {
          menuItems.push({
            key: '1',
            label: <CustomUpdateIcon link={`/products/${record.id}`} />
          });
        }
        if (checkAccess('READ_CHANNEL')) {
          menuItems.push({
            key: '3',
            label: (
              <DiscussionChatModal slug="products" rreference="other" id={record.id as number} />
            )
          });
        }

        if (checkAccess('UPDATE_PRODUCT')) {
          const isHidden = location
            ? checkProductLocationHidden(record, location.id)
            : record.hidden;

          menuItems.push({
            key: '2',
            label: (
              <HideConfirm
                onOk={() => onHideProduct(record)}
                hideFor="product"
                target={record.name}
                additionalTooltip={
                  location ? `for the selected location: ${location?.name}` : 'Globally'
                }
                isHidden={isHidden}
                additionalMessage={
                  <span>
                    {`This product will be ${!isHidden ? 'hidden' : 'unhidden'} ${
                      location ? `for the selected location: ${location?.name}` : 'globally'
                    }.`}
                  </span>
                }
              />
            )
          });
        }

        const menu = <Menu items={menuItems} />;
        return <ActionDropdown menu={menu} />;
      }
    }
  ];

  if (location) {
    columns.splice(columns.length - 1, 0, {
      title: 'Location Status',
      key: 'locationStatus',
      width: 14,
      sorter: (a, b) =>
        +checkProductLocationHidden(a, location.id) - +checkProductLocationHidden(b, location.id),
      sortOrder: sortedInfo.columnKey === 'locationStatus' ? sortedInfo.order : null,
      render: (record: IProductType) => {
        const isHidden = checkProductLocationHidden(record, location.id);
        return <Tag color={isHidden ? 'red' : 'green'}>{isHidden ? 'Inactive' : 'Active'}</Tag>;
      }
    });
  }

  const breadcrumbItems = [
    { label: `Products ${location ? `(${location.name})` : ''}`, link: '/products' }
  ];

  async function getInfo(filter = '') {
    const productResponse = await get_product_list_all_status(filter);
    const productLists = JSON.parse(JSON.stringify(productResponse.data)) as IProductListTableData;

    const defaultUnitAbsentProducts = [];

    for (const product of productLists.results) {
      product.hiddenStatus = product.hidden ? 'Inactive' : 'Active';
      const defaultUnit = product?.productUnits?.find((p) => p.isDefault);
      if (defaultUnit) {
        let unitDetails = await UnitsDB.getUnit(defaultUnit.unitId);
        if (!unitDetails) {
          const allUnits = await get_units_list();
          await UnitsDB.addUnits(allUnits);
          unitDetails = await UnitsDB.getUnit(defaultUnit.unitId);
        }

        if (typeof unitDetails === 'object') {
          product.defaultUnit = unitDetails.shortName;
        }
      } else {
        defaultUnitAbsentProducts.push(product);
      }
    }

    if (defaultUnitAbsentProducts.length > 0) {
      const message = (
        <div>
          <span>The following products have default units missing.</span>
          <ul className="pl-4 mb-0 mt-2">
            {defaultUnitAbsentProducts.map((product) => (
              <li key={product.id}>{product.name}</li>
            ))}
          </ul>
          <br />
        </div>
      );

      CustomInfoModal({ message, title: 'Warning' });
    }

    return { productLists, original: productResponse.data.results };
  }

  const onSubmitFilter = async (val: string) => {
    const query = new URLSearchParams(val);
    const locationId = query.get('locationId');
    setIsloading(true);

    const parseLocationId = locationId ? Number(locationId) : undefined;
    const isInValidLocation = !parseLocationId || isNaN(parseLocationId);
    const location = isInValidLocation ? undefined : await getLocationByDetail(parseLocationId);

    const { productLists, original } = await getInfo(val);
    setLocation(location ? { id: location.id, name: location.name } : undefined);
    setAllProducts(productLists);

    setIsloading(false);
    ProductsDB.addProducts(original);
    setPage(1);
    setSize(100);
    return productLists;
  };
  const { Option } = Select;

  const getDataFromLC = async () => {
    const allunits: IUnits[] = (await UnitsDB.getAllUnits()) as IUnits[];
    if (allunits.length > 0) {
      setUnitsList(allunits);
    } else {
      const response = await get_units_list();
      setUnitsList(response);
      UnitsDB.addUnits(response);
    }
    const allcategory: ICategory[] = (await CategoryDB.getAllCategory()) as ICategory[];
    if (allcategory.length > 0) {
      setCategoryList(allcategory);
    } else {
      const response = await get_category_list();
      setCategoryList(response.data.results);
      CategoryDB.addCategory(response.data.results);
    }
  };
  useEffect(() => {
    getDataFromLC();
  }, []);

  //pagination Data
  const onPagination = async (pageNo = 1, totalSize = 100, isSize = false) => {
    setIsloading(true);
    const values = form.getFieldsValue();
    values.endDate = convertLocalToUTCString(values.endDate);
    values.startDate = convertLocalToUTCString(values.startDate);
    delete values.dateCustom;
    delete values.startDateNepali;
    delete values.endDateNepali;
    if (isSize) {
      values.skip = 0;
      values.count = totalSize;
      setPage(1);
      setSize(totalSize);
    } else {
      values.skip = (pageNo - 1) * totalSize;
      values.count = totalSize;
      setPage(pageNo);
    }
    const url = ConvertObjectToURL(values);
    const { productLists, original } = await getInfo(url);
    setAllProducts(productLists);
    setIsloading(false);
    ProductsDB.addProducts(original);
    return productLists;
  };

  const handleExport = () => {
    setIsloading(true);

    try {
      const columns = [
        { title: 'S.N', dataIndex: 'SN', width: 50 },
        { title: 'Id', dataIndex: 'id', width: 50 },
        { title: 'Name', dataIndex: 'name', width: 300 },
        { title: 'Default Unit', dataIndex: 'defaultUnit', width: 50 },
        { title: 'Active', width: 200, dataIndex: 'hiddenStatus' },
        { title: 'SKU', width: 150, dataIndex: 'sku' },
        { title: 'Lot Expiry Duration', width: 150, dataIndex: 'lotExpiryDuration' },
        { title: 'Vat %', width: 150, dataIndex: 'vat' }
      ];

      if (allProducts.results.length === 0) {
        message.error('No Data to Export');
        return;
      }

      const dataUpdated = allProducts.results.map((item: IProductType, index: number) => {
        return {
          ...item,
          SN: index + 1,
          hiddenStatus: item.hidden ? 'Inactive' : 'Active'
        };
      });

      exportExcelAutoWidth(columns, dataUpdated, 'Products List');
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsloading(false);
    }
  };

  const handlePDFExport = useReactToPrint({
    content: () => printPDFRef.current
  });

  const columsforPrint: IColumnDataForCustomTable[] = [
    {
      label: 'ID',
      dataIndex: 'id',
      render: (text: string) => {
        return <div className="ml-2">{text}</div>;
      }
    },
    {
      label: 'Name',
      dataIndex: 'name',
      render: (text: string) => {
        return <div className="ml-2">{text}</div>;
      }
    },
    {
      label: 'Default Unit',
      dataIndex: 'defaultUnit',
      render: (text: string) => {
        return <div className="ml-2">{text}</div>;
      }
    },
    {
      label: 'Active',
      dataIndex: 'hiddenStatus',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'SKU',
      dataIndex: 'sku',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Lot Expiry Duration',
      dataIndex: 'lotExpiryDuration',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    },
    {
      label: 'Vat %',
      dataIndex: 'vat',
      render: (text: string) => {
        return <div className="text-center">{text}</div>;
      }
    }
  ];

  return (
    <Spin spinning={isloading}>
      <CustomModal
        footer={false}
        isModalOpen={openmodalforExport}
        setIsModalOpen={setOpenModalforexport}
        title="Product List View For PDF print">
        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
          <CustomTable
            columns={columsforPrint}
            datas={allProducts.results}
            reff={printPDFRef}
            title={'Product List'}
          />
          <div className="flex justify-end mt-3">
            <Button type="primary" onClick={handlePDFExport}>
              Print Pdf
            </Button>
          </div>
        </div>
      </CustomModal>
      <AppContent
        breadcrumbItems={breadcrumbItems}
        withfilter={true}
        button={
          <>
            <div>
              <TableFilter
                form={form}
                onInitialLoad={async ({ data, pagination }) => {
                  setPage(pagination.page);
                  setSize(pagination.size);

                  const locationId = form.getFieldValue(['locationId']);
                  if (locationId) {
                    const location = await getLocationByDetail(locationId);
                    setLocation({ id: location.id, name: location.name });
                  }

                  if (data) {
                    setAllProducts(data);
                    setIsloading(false);
                  }
                }}
                defaultValues={{
                  skip: 0,
                  count: 100,
                  dateCustom: [moment(0, 'HH'), moment(0, 'HH').add(1, 'days')],
                  value: '',
                  locationId: preferenceLocationId ? preferenceLocationId : '',
                  unitId: '',
                  categoryId: '',
                  taxId: '',
                  hidden: false,
                  brandId: '',
                  locationHidden: false
                }}
                initial={true}
                onSubmit={onSubmitFilter}
                style={
                  'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3  xl:grid-cols-4 gap-2 items-center justify-center'
                }
                styleforbuttons={'flex justify-end items-center'}
                buttons={
                  <>
                    {checkAccess('CREATE_PRODUCT') && (
                      <div>
                        <CustomButton
                          onClick={() => navigate(`/products/new`)}
                          text="Add"
                          backgroundColor="#1890ff"
                          Linkto="/products/new"
                        />
                      </div>
                    )}

                    {/* <CustomButton text="Export" backgroundColor="#1890ff" onClick={handleExport} />
                    <CustomButton
                      text="PDF Export"
                      backgroundColor="#1890ff"
                      onClick={() => setOpenModalforexport(true)}
                    /> */}
                  </>
                }>
                <Form.Item label={'Search'} name={['value']}>
                  <Input />
                </Form.Item>
                <LocationSearchV2 hasParentFormItem={false} name={'locationId'} showAll />
                <Form.Item name="categoryId" label="Category">
                  <Select
                    placeholder="Select Category"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    dropdownMatchSelectWidth={false}>
                    <Option value={''} key={'none'}>
                      All
                    </Option>
                    {categoryList?.map((value: ICategory) => {
                      return (
                        <Select.Option value={value.id} key={value.id}>
                          {value.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="hidden" label="Status" hidden>
                  <Select
                    placeholder="Select"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    dropdownMatchSelectWidth={false}>
                    <Option value={false} key={'false'}>
                      Active
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item name="locationHidden" label="Status" hidden>
                  <Select
                    placeholder="Select"
                    style={{ borderRadius: '10px' }}
                    allowClear
                    dropdownMatchSelectWidth={false}>
                    <Option value={false} key={'false'}>
                      Active
                    </Option>
                  </Select>
                </Form.Item>
              </TableFilter>
            </div>
          </>
        }>
        <CustomizeTable
          form={form}
          columns={columns}
          buttons={
            <div>
              <ActionDropdown
                button={true}
                menu={
                  <Menu
                    items={[
                      {
                        key: '1',
                        label: (
                          <Tooltip title="Export Excel" color="blue">
                            <div className="text-center">Excel</div>
                          </Tooltip>
                        ),
                        onClick: () => {
                          handleExport();
                        }
                      },
                      {
                        key: '2',
                        label: (
                          <Tooltip title="Export PDF" color="blue">
                            <div className="text-center">PDF</div>
                          </Tooltip>
                        ),
                        onClick: () => {
                          setOpenModalforexport(true);
                        }
                      }
                    ]}
                  />
                }
              />
            </div>
          }
          data={allProducts.results}
          notshowPagination={true}
          paginationDatas={{
            page: page,
            total: allProducts.total,
            size: size,
            onPagination
          }}
          tableName={'products-list'}
          toSort={handleChange}
          customScroll={{ y: '75vh', x: 1200 }}
        />
      </AppContent>
    </Spin>
  );
};

export default List;
