import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Form, Input, Button, PageHeader, message, Upload, Select, Spin } from 'antd';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { createPartiallyEmittedExpression } from 'typescript';
import AppContent from '../../../components/Common/Content/Content';
import { LocationSearch } from '../../../components/Common/LocationSearch/LocationSearch';
import { API_URL } from '../../../constants/config';
import { checkAccess } from '../../../routes/acl';
import { get_location_list } from '../../../services/locations/queries';
import { ILocations } from '../../../services/locations/types';

import {
  get_price_groups_by_location,
  prices_download_by_pricegroupid
} from '../../../services/products/queries';
import { getLocalStorage } from '../../../utils/storage.utils';
import ReuseChannel from '../../channel/Reuse';
const { Option } = Select;

const PriceGroupsDownload = () => {
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<any>('none');
  const [priceGroupId, setPriceGroupId] = useState<any>('none');
  const [priceGroup, setPriceGroup] = useState<any[]>([]);
  const [responsize, setResponsize] = useState<any>(false);
  const { data: locationsList } = useQuery(['locations'], async () => get_location_list());

  const breadcrumbItems = [
    {
      label: 'Products',
      link: '/products'
    },
    {
      label: 'Price Group Download',
      link: '/products/price-group-download'
    }
  ];
  const handleDownload = async () => {
    setIsloading(true);
    if (locationId === 'none' || priceGroupId == 'none') {
      message.error('Please select both location and price group!');
      setIsloading(false);
      return;
    }
    try {
      const response = await prices_download_by_pricegroupid(priceGroupId);
      window.open(response.data, '_blank');
    } catch (error: any) {
      message.error('unable to fetch');
    }
    setIsloading(false);
  };

  const onLocationsChange = async (val: any) => {
    try {
      setLocationId(val);
      const response = await get_price_groups_by_location(val);
      setPriceGroup(response.data);
      if (response.data.length > 0) {
        setPriceGroupId(response.data[0].id);
      } else {
        setPriceGroupId('none');
      }
    } catch (error: any) {
      message.error('unable to fetch price group');
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 650) {
      // setCollapsed(true);
      setResponsize(true);
    } else {
      setResponsize(false);
    }
    function handleResize() {
      if (window.innerWidth <= 650) {
        // setCollapsed(true);
        setResponsize(true);
      } else {
        setResponsize(false);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div>
      <Spin spinning={isloading}>
        <AppContent breadcrumbItems={breadcrumbItems}>
          <PageHeader
            title="Price Information by Location"
            style={{
              padding: '8px 0px'
            }}
          />
          <div
            className={
              responsize ? 'grid grid-cols-1 gap-5 mb-5' : 'grid grid-cols-3 gap-5 mt-10 mb-5'
            }>
            <LocationSearch value={locationId} onSelect={onLocationsChange} withoutForm={true} />
            <Select
              placeholder="Select a price group!"
              value={priceGroupId}
              onChange={(val) => setPriceGroupId(val)}>
              {priceGroup?.map((value: any) => {
                return (
                  <Option value={value.id} key={value.id}>
                    {value.name}
                  </Option>
                );
              })}
            </Select>
            <Button onClick={handleDownload} disabled={isloading} type={'primary'}>
              Download
            </Button>
          </div>
          {checkAccess('READ_CHANNEL') && <ReuseChannel slug={`price_download_${null}`} />}
        </AppContent>
      </Spin>
    </div>
  );
};

export default PriceGroupsDownload;
