import { message, Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';

import AppContent from '@/components/Common/Content/Content';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { useEffect, useState } from 'react';
import { useFilterStore, useHydration } from '@/store/zustand';
import getErrorMessage from '@/utils/getError';
import { checkAccess } from '@/routes/acl';
import { get_woo_commerce_categories } from '@/services/woocommerce/queries';
import { IWooCategory } from '@/services/woocommerce/types';
import PreviewMultipleImage from '@/components/Common/PreviewMultipleImage';
import GenericTable from '@/components/Common/CustomizeTable';
import RefreshButton from '@/components/Button/RefreshButton';
import { sync_woo_category } from '@/services/woocommerce/mutation';

function WooCommerceCategories() {
  const [data, setData] = useState<IWooCategory[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const zustandFilter = useFilterStore();
  const location = useLocation();
  const isHydrating = useHydration();

  async function fetchCategories() {
    try {
      setIsLoading(true);
      const data = await get_woo_commerce_categories();
      const wooCategories = data.map((d) => d.wooCategory);
      setData(wooCategories);
      zustandFilter.updateState(location.pathname, { data: wooCategories });
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    (async function () {
      try {
        setIsLoading(true);
        if (!isHydrating) return;

        const currentState = zustandFilter.getState(location.pathname);
        if (currentState && currentState.data) {
          setData(currentState.data as IWooCategory[]);
          setIsLoading(false);
          return;
        }

        fetchCategories();
      } catch (error) {
        message.error(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isHydrating]);

  const handleSyncToWooCommerce = async () => {
    const instance = Modal.confirm({
      title: 'Confirm Sync',
      content: 'Are you sure you want to sync ERP categories to WooCommerce?',
      okText: 'Yes, Sync',
      cancelText: 'Cancel',

      onOk: async () => {
        instance?.destroy();
        setIsLoading(true);
        try {
          await sync_woo_category();
          await fetchCategories(); // Refresh Categories
          message.success('Synced Successfully');
        } catch (error) {
          getErrorMessage(error, true);
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const columns: ColumnsType<IWooCategory> = [
    { title: 'S.N', key: 'id', width: 15, render: (_, __, index) => index + 1 },
    { title: 'Name', dataIndex: 'name', key: 'name', width: 35 },
    { title: 'Slug', dataIndex: 'slug', key: 'slug', width: 35 },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 75
    },
    { title: 'Products', dataIndex: 'count', key: 'count', width: 20 },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (i, r) => (r.image ? <PreviewMultipleImage medias={[r.image.src]} /> : ''),
      width: 15
    }
  ];

  return (
    <AppContent
      breadcrumbItems={[
        { label: 'WooCommerce', link: '/woo-commerce/products' },
        { label: 'Categories' }
      ]}
      button={
        <div>
          <RefreshButton disabled={isLoading} onRefresh={fetchCategories} />
          {checkAccess('UPDATE_WOO_COMMERCE') && (
            <CustomButton
              disabled={isLoading}
              text="Sync Category"
              backgroundColor="#1890ff"
              onClick={handleSyncToWooCommerce}
            />
          )}
        </div>
      }>
      <GenericTable
        allowScrollPersist
        columns={columns}
        data={data || []}
        isLoading={isLoading}
        hideDefaultPagination
        scroll={{ x: 800, y: '75vh' }}
        tableName={'woo-categories-list'}
      />
    </AppContent>
  );
}

export default WooCommerceCategories;
