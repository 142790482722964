import AppContent from '@/components/Common/Content/Content';
import { ConvertObjectToURL } from '@/utils/converturl';
import getErrorMessage from '@/utils/getError';
import handlePagination from '@/utils/handlePagination';
import { Form, Input } from 'antd';
import { useState } from 'react';
import Table from './Table';
import TableFilter from '@/components/FliterTable';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { getProductById, getUnit } from '@/services';
import { get_woo_products_list } from '@/services/woocommerce/queries';
import { IWooCommerceProductsResponse } from '@/services/woocommerce/types';
import ProductsDB from '@/store/localstorage/ProductsDB';
import { checkAccess } from '@/routes/acl';
import ProductSearchV2 from '@/components/Common/CustomSearch/Products';
import { parseMultipleParams } from '@/utils/parseMultipleParams';
import { WooCommerceProductsDB } from '@/store/localstorage/WooCommerceProductsDB';

const DEFAULT_COUNT = 100;

function WooCommerceProductList() {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({ page: 1, size: DEFAULT_COUNT });
  const [data, setData] = useState<IWooCommerceProductsResponse>({ results: [] });

  async function onSubmitFilter(filter: string) {
    const data = await getInfo(filter.toString());
    setPagination({ page: 1, size: DEFAULT_COUNT });
    return { data };
  }

  function convertSkipToPage(filter: string) {
    const params = new URLSearchParams(filter);
    const paramSkip = params.get('skip');
    const paramsCount = params.get('count');

    const skip = paramSkip ? parseInt(paramSkip) : 0;
    const count = paramsCount ? parseInt(paramsCount) : DEFAULT_COUNT;

    // Calculate page from skip and count
    const page = Math.floor(skip / count) + 1;
    params.delete('skip');
    params.set('page', page.toString());
    return params.toString();
  }

  async function getInfo(filter = '', isExport = false) {
    try {
      setIsLoading(true);
      const params = convertSkipToPage(filter);
      const updatedFilter = parseMultipleParams(params, 'erpProductIds', 'erpProductIds[]');
      const response = await get_woo_products_list(updatedFilter);
      await WooCommerceProductsDB.add([...response.results]);

      const uniqueProductIds = Array.from(new Set(response.results.map((a) => a.erpProdId)));
      await ProductsDB.addProductsIfAbsent(uniqueProductIds);

      for (const product of response.results) {
        const [productDetails, unit] = await Promise.all([
          getProductById(product.erpProdId),
          getUnit(product.unitId)
        ]);

        product.productName = productDetails.name;
        product.unitName = unit.name;
      }

      if (!isExport) {
        setData(response);
      }
      return response;
    } catch (error) {
      getErrorMessage(error, true);
    } finally {
      setIsLoading(false);
    }
  }

  const onPagination = async (page = 1, size = DEFAULT_COUNT, isSize = false) => {
    setIsLoading(true);
    const { values, ...pagination } = handlePagination({
      formValues: form.getFieldsValue(),
      page,
      size,
      isSize
    });

    setPagination((prev) => {
      prev.page = pagination.page;
      if (isSize) prev.size = pagination.size;
      return prev;
    });

    const url = ConvertObjectToURL(values);
    const data = await getInfo(url);
    return { data };
  };

  return (
    <div>
      <AppContent
        breadcrumbItems={[
          { label: 'WooCommerce', link: '/woo-commerce/products' },
          { label: 'Products' }
        ]}
        withfilter
        button={
          <TableFilter
            initial
            form={form}
            dateCustom={false}
            onInitialLoad={({ data, pagination }) => {
              setPagination(pagination);
              if (data) {
                if (data.data) setData(data.data);
                setIsLoading(false);
              }
            }}
            buttons={
              <>
                {checkAccess('CREATE_WOO_COMMERCE') && (
                  <CustomButton
                    text="Add"
                    backgroundColor="#1890ff"
                    Linkto="/woo-commerce/products/new"
                  />
                )}
              </>
            }
            onSubmit={onSubmitFilter}
            defaultValues={{ value: '', skip: 0, count: DEFAULT_COUNT }}
            styleforbuttons={'flex justify-end items-center'}>
            <ProductSearchV2 name={'erpProductIds'} isMultiple hasParentFormItem={false} />

            <Form.Item name="value" label="Search">
              <Input placeholder="Search" />
            </Form.Item>
          </TableFilter>
        }>
        <Table
          form={form}
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          onPagination={onPagination}
        />
      </AppContent>
    </div>
  );
}

export default WooCommerceProductList;
