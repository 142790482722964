import GenericTable from '@/components/Common/CustomizeTable';

import { FormInstance, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';

import { IWooCommerceProduct, IWooCommerceProductsResponse } from '@/services/woocommerce/types';
import CustomButton from '@/components/Common/CustomButton/CustomButton';
import { checkAccess } from '@/routes/acl';
import { Link } from 'react-router-dom';

interface Props {
  isLoading?: boolean;
  data: IWooCommerceProductsResponse;
  form: FormInstance;
  pagination: { page: number; size: number };
  onPagination: (
    page?: number,
    size?: number,
    isSize?: boolean
  ) => Promise<{ data: IWooCommerceProductsResponse | undefined }>;
}

function Table({ isLoading, data, pagination, form, onPagination }: Props) {
  const [sortedInfo, setSortedInfo] = useState<SorterResult<IWooCommerceProduct>>({});
  const fallbackTotal = getTotalCount(data, pagination);

  function getTotalCount(
    data: IWooCommerceProductsResponse,
    pagination: { page: number; size: number }
  ): number {
    const { page, size } = pagination;
    const itemsCount = data.results?.length ?? 0;

    if (page === 1 && itemsCount === 0) return 0;
    if (page > 1 && itemsCount === 0) return (page - 1) * size;

    return (page - 1) * size + itemsCount * 2;
    // Fake total calculation
    // Sometimes, server send 4 items for count 5
  }

  const handleChange: TableProps<IWooCommerceProduct>['onChange'] = (_, __, s) => {
    setSortedInfo(s as SorterResult<IWooCommerceProduct>);
  };

  const columns: ColumnsType<IWooCommerceProduct> = [
    {
      title: 'S.N.',
      key: 'id',
      width: 3,
      render: (_, __, index) => (pagination.page - 1) * pagination.size + index + 1
    },
    {
      title: 'Product',
      key: 'name',
      dataIndex: 'name',
      width: 20,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null
    },
    {
      title: 'ERP Name',
      key: 'productName',
      dataIndex: 'productName',
      width: 20,
      sorter: (a, b) => a.productName.localeCompare(b.productName),
      sortOrder: sortedInfo.columnKey === 'productName' ? sortedInfo.order : null,
      render: (name, record) => (
        <Link to={`/products/${record.erpProdId}`} target="_blank">
          {name}
        </Link>
      )
    },
    {
      title: 'Unit',
      key: 'unitName',
      dataIndex: 'unitName',
      width: 5,
      sorter: (a, b) => a.unitName.localeCompare(b.unitName),
      sortOrder: sortedInfo.columnKey === 'unitName' ? sortedInfo.order : null
    },
    {
      title: 'Path',
      key: 'url',
      dataIndex: 'url',
      width: 5,
      render: (url) => (
        <a href={url} target="_blank" rel="noreferrer noopener">
          View
        </a>
      )
    },
    {
      title: 'Qty Limit',
      key: 'orderQtyLimit',
      dataIndex: 'orderQtyLimit',
      width: 5,
      sorter: (a, b) => a.orderQtyLimit - b.orderQtyLimit,
      sortOrder: sortedInfo.columnKey === 'orderQtyLimit' ? sortedInfo.order : null
    },
    {
      title: 'Selling Price',
      key: 'sellPrice',
      dataIndex: 'sellPrice',
      width: 5,
      sorter: (a, b) => parseFloat(a.sellPrice) - parseFloat(b.sellPrice),
      sortOrder: sortedInfo.columnKey === 'sellPrice' ? sortedInfo.order : null
    }
  ];

  return (
    <div>
      <GenericTable
        form={form}
        columns={columns}
        buttons={
          <>
            {checkAccess('CREATE_WOO_COMMERCE') && (
              <CustomButton
                text="Update"
                marginTop="0px"
                backgroundColor="#1890ff"
                Linkto="/woo-commerce/products/sync"
              />
            )}
          </>
        }
        data={data.results}
        isLoading={isLoading}
        scroll={{ x: 1100, y: '75vh' }}
        hideDefaultPagination
        showPager={false}
        tableName={'woo-products-list'}
        pagination={{
          ...pagination,
          total: data?.totalCount || fallbackTotal,
          onPagination,
          scrollToTop: true,
          showSizeChanger: false
        }}
        toSort={handleChange}
      />
    </div>
  );
}

export default Table;
