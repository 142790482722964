import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Spin,
  Table,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Option } from 'antd/lib/mentions';
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AppContent from '@/components/Common/Content/Content';
import {
  get_lots_details_bylocationId_productId,
  get_product_list,
  get_product_list_ids,
  get_unexpired_lots_details_bylocationId_productIds,
  get_units_list
} from '@/services/products/queries';
import { ILotDetails, IProductType, IUnits } from '@/services/products/types';
import { create_purchase_return_mutation } from '@/services/purchases/mutations';
import { get_purchase_details } from '@/services/purchases/queries';
import { get_vendor_details } from '@/services/users/queries';
import ProductsDB from '@/store/localstorage/ProductsDB';
import UnitsDB from '@/store/localstorage/UnitsDB';
import { numberDecimalFormatter } from '@/utils/numberFormatter';
import { ILines } from '@/services/sell/types';
import { ICreatePurchaseReturnRequest, Line } from '@/services/purchases/types';
import { WebSocketContext } from '@/contexts/websocket.context';
import { SocketEvents, SystemNotificationType } from '@/constants/websocketConfig';
import { checkHasAccountRule } from '@/services/accounts/services';
import { AccountRulesEvent, AccountType } from '@/services/accounts/enums';
import CustomInfoModal from '@/components/Common/CustomInfoModal';
import DebounceButton from '@/components/Common/DebounceButton';
import { getVendors } from '@/pages/expense/view/services.expense';
import { get_account_details_by_userid_type } from '@/services/accounts/queries';

const { Text } = Typography;

const breadcrumbItems = [
  { label: 'Purchases', link: '/purchase' },
  { label: 'Purchases Return', link: '/purchase/return' },
  { label: 'Return' }
];

const PurchaseReturnV2: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { socket } = useContext(WebSocketContext);
  const [locationId, setLocationId] = useState<number>();
  const [data, setData] = useState<ILines[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasRule, setHasRule] = useState(true);
  const [editingKey, setEditingKey] = useState<number>();
  const isEditing = (record: ILines) => record.id === editingKey;
  const [totalLots, settotalLots] = useState<any>([]);
  const [allLocalUnits, setAllLocalUnits] = useState<IUnits[]>([]);
  const [isAccountArchived, setIsAccountArchived] = useState(false);

  const { data: purchaseDetails, refetch } = useQuery(['purchase-details'], async () => {
    const response = await get_purchase_details(parseInt(id as string));
    // console.log('response', response);
    // const responseLines = await get_purchase_lines_details(parseInt(id as string));
    if (response?.lines) {
      const vendor = await get_vendor_details(response.purchase.vendorId);
      console.log(vendor);
      const vendorId = vendor.vendor.id;

      if (vendorId) {
        const vendorDetails = await getVendors(vendorId);
        if (typeof vendorDetails !== 'string') {
          const userId = vendorDetails.userId;
          const userAccount = await get_account_details_by_userid_type(userId, AccountType.USER);

          setIsAccountArchived(userAccount?.isArchived);
          if (userAccount?.isArchived) {
            message.error({
              content: (
                <span>
                  Unable to create purchase return though this vendor. <strong>Reason:</strong>{' '}
                  <span className="text-red-500">Account Archived</span>
                </span>
              ),
              duration: 5
            });
          }
        }
      }

      response.purchase.vendorName = vendor.user.user.name;
      const productIdAndReturnQuanitityMap: any = {};
      const mySet = new Set<number>();
      const searchProducts: any = {};
      for (let ind = 0; ind < response.returnLines.length; ind++) {
        if (!productIdAndReturnQuanitityMap[response.returnLines[ind].productId]) {
          productIdAndReturnQuanitityMap[response.returnLines[ind].productId] =
            response.returnLines[ind].quantity;
        } else {
          productIdAndReturnQuanitityMap[response.returnLines[ind].productId] +=
            response.returnLines[ind].quantity;
        }
      }

      // console.log('productandreturnquanititymap', productIdAndReturnQuanitityMap);

      for (let index = 0; index < response.lines.length; index++) {
        mySet.add(response.lines[index].productId);
        const product: any = await ProductsDB.getProduct(response.lines[index].productId);
        let unit: any = await UnitsDB.getUnit(response.lines[index].unitId);
        if (!product) {
          if (response.lines[index].productId in searchProducts) {
            searchProducts[response.lines[index].productId] = [
              ...searchProducts[response.lines[index].productId],
              index
            ];
          } else {
            searchProducts[response.lines[index].productId] = [index];
          }
        } else {
          response.lines[index].productName = product.name;
        }

        if (!unit) {
          const unitsResponse = await get_units_list();
          await UnitsDB.addUnits(unitsResponse);
          unit = await UnitsDB.getUnit(response.lines[index].unitId);
        }

        response.lines[index].alreadyreturnQuantity = productIdAndReturnQuanitityMap[
          response.lines[index].productId
        ]
          ? productIdAndReturnQuanitityMap[response.lines[index].productId]
          : 0;
        response.lines[index].unitName = unit.name;
        response.lines[index].allowQtyDecimal = unit.allowDecimal;
        response.lines[index].totalQuantity = response.lines[index].quantity;
        response.lines[index].quantity = 0;
        response.lines[index].totalReturn =
          response.lines[index].quantity * response.lines[index].unitPrice;
        // response.lines[index].qtyAvailable =
      }

      const searchProductslength = Object.entries(searchProducts).length;
      if (searchProductslength > 0) {
        const productsresponse = await get_product_list_ids([...Object.keys(searchProducts)]);
        for (const key in searchProducts) {
          const findproduct = productsresponse?.data?.results.find(
            (currProduct: IProductType) => currProduct.id == key
          );
          for (let i = 0; i < searchProducts[key].length; i++) {
            response.lines[searchProducts[key][i]].productName = findproduct?.name;
          }
          await ProductsDB.addProducts([findproduct]);
        }
      }
      setLocationId(response?.purchase?.locationId);
      await checkAccountRule(response?.purchase?.locationId);
      const totalLots = await fetchLotsOnLocationandProductChange(
        Array.from(mySet),
        response?.purchase?.locationId,
        'location'
      );
      // console.log(response.lines);
      form.setFieldValue(['lines'], response.lines);
      form.setFieldValue(['note'], '');
      if (totalLots) {
        setData(tempFix(totalLots));
        const data = tempFix(totalLots);
        data.forEach((element: any, index: number) => {
          calculateTotalReturn(
            element.productId,
            element.quantity,
            index,
            element.unitPrice,
            element.unitDiscount
          );
        });
      } else {
        setData(tempFix());
        const data = tempFix();
        data.forEach((element: any, index: number) => {
          calculateTotalReturn(
            element.productId,
            element.quantity,
            index,
            element.unitPrice,
            element.unitDiscount
          );
        });
      }
    }
    setIsLoading(false);

    return response;
  });

  const tempFix = (totalLots?: any) => {
    let formData = form.getFieldValue(['lines']);

    formData = formData.map((item: any) => {
      let sortedLot = [];
      if (item.productId && totalLots) {
        let filteredLots: any = [];
        if (totalLots.length !== 0) {
          filteredLots = totalLots.filter((currLot: any) => currLot.productId === item.productId);
        }

        // sort lots by grade and qty
        sortedLot = [...filteredLots].sort((a, b) => {
          if (a.grade === b.grade) {
            return b.qtyAvailable - a.qtyAvailable;
          } else {
            return a.grade.localeCompare(b.grade);
          }
        });
      }
      if (sortedLot.length > 0) {
        return {
          ...item,
          lotId: sortedLot[0].id,
          unitDiscount: item.discount / item.totalQuantity
        };
      } else {
        return { ...item, lotId: null, unitDiscount: item.discount / item.totalQuantity };
      }
    });
    form.setFieldValue(['lines'], formData);
    return formData;
  };

  const checkAccountRule = async (locationId: number) => {
    if (
      (await checkHasAccountRule(locationId, AccountRulesEvent.PURCHASE_RETURN)) &&
      (await checkHasAccountRule(locationId, AccountRulesEvent.VAT_CREATE))
    ) {
      setHasRule(true);
    } else {
      setHasRule(false);
      CustomInfoModal({
        title: 'Info',
        message: `"${AccountRulesEvent.PURCHASE_RETURN}" or "${AccountRulesEvent.VAT_CREATE}" rule has not been created!`
      });
    }
  };

  useEffect(() => {
    socket?.on('connect', async () => {
      // console.log('Socket Reconnected');
      const productIds = new Set<number>(
        purchaseDetails?.lines.map((value) => {
          return value.productId;
        })
      );
      if (locationId) {
        await fetchLotsOnLocationandProductChange([...productIds], locationId, 'lotsupdate');
      }
    });

    socket?.on(SocketEvents.SYSTEM_NOTIFICATION, async (data) => {
      if (data.type === SystemNotificationType.LOTS_ZERO) {
        const socketData = data.data as { locationId: number };
        if (socketData.locationId === locationId) {
          settotalLots((prev: any) => {
            return prev.map((a: any) => ({ ...a, qtyAvailable: 0 }));
          });
        }
      }

      if (data.type === SystemNotificationType.LOTS_UPDATE) {
        const productIds = new Set<number>(
          purchaseDetails?.lines.map((value) => {
            return value.productId;
          })
        );

        let updatedProducts = data.data as { productId: number; locationId: number }[];
        if (locationId) {
          updatedProducts = updatedProducts.filter(
            (value) =>
              value.locationId === locationId && Array.from(productIds).includes(value.productId)
          );
        }

        if (updatedProducts.length > 0) {
          if (locationId) {
            const updatedProductIds = updatedProducts.map((value) => value.productId);
            await fetchLotsOnLocationandProductChange(updatedProductIds, locationId, 'lotsupdate');
          }
        }
      }
    });

    return () => {
      socket?.off(SocketEvents.SYSTEM_NOTIFICATION);
    };
  }, [socket?.connected, locationId, purchaseDetails]);

  const FilterLot = (
    checkCurrentProduct: number,
    currentLocation: number,
    unitId: number,
    index: number
  ) => {
    // const checkCurrentProduct = form.getFieldValue(['lines', name, 'productId']);

    // const currentLocation = form.getFieldValue(['locationId']);

    if (checkCurrentProduct && currentLocation) {
      // const filteredLots = allLots.filter((value: Line) => value.productId == checkCurrentProduct);
      let filteredLots: any = [];
      // console.log('totalLots-->', totalLots);
      if (totalLots.length !== 0) {
        filteredLots = totalLots.filter(
          (currLot: any) => currLot.productId === checkCurrentProduct
        );
        // console.log('filtered Lots-->', filteredLots);
      }
      // const unitId = form.getFieldValue(['lines', name, 'unitId']);
      const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
      return (
        <>
          {filteredLots?.map((value: any) => {
            const isExpired = value?.expirationDate
              ? moment().isAfter(value.expirationDate)
              : false;

            return (
              <Option
                value={value.id}
                key={value.id}
                style={{ color: value.qtyAvailable > 0 && !isExpired ? 'green' : 'red' }}>
                {`(${value.qtyAvailable / (unitInfo?.baseUnitMultiplier || 1)} ${
                  unitInfo?.shortName || ''
                }) ${value.lotNumber}`}{' '}
                {`Grade-${value.grade} Expiry-${
                  value?.expirationDate
                    ? new Date(value.expirationDate).toLocaleDateString()
                    : 'N/A'
                }`}
              </Option>
            );
          })}
        </>
      );
    }
  };

  const fetchLotsOnLocationandProductChange = async (
    productsIdArray: number[],
    locationId: number,
    from: string
  ) => {
    if (!locationId) {
      throw {
        name: 'Location Error',
        message: 'Please select Location!'
      };
    }

    if (productsIdArray.length === 0) {
      return [];
    }
    const currenttotalLots = [];
    if (from === 'productchange') {
      const filterLots = totalLots.find((value: ILines) => value.productId == productsIdArray[0]);
      if (!filterLots) {
        const response = await get_lots_details_bylocationId_productId(
          locationId,
          productsIdArray[0]
        );
        settotalLots([...totalLots, ...response]);

        return [...totalLots, ...response];
      }
    } else if (from === 'lotsupdate') {
      const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
        ...new Set(productsIdArray)
      ]);

      settotalLots((prev: ILotDetails[]) => {
        const filterLots = prev.filter((value) => !productsIdArray.includes(value.productId));
        return [...filterLots, ...result];
      });
    } else {
      const result = await get_unexpired_lots_details_bylocationId_productIds(locationId, [
        ...new Set(productsIdArray)
      ]);
      currenttotalLots.push(...result);
      settotalLots([...currenttotalLots]);

      return [...currenttotalLots];
    }
  };

  const calculateTotalReturn = async (
    productId: number,
    value: number,
    index: number,
    unitPrice: number,
    unitDiscount: number
  ) => {
    let productDetails = await ProductsDB.getProduct(productId);
    if (!productDetails) {
      const allProducts = await get_product_list();
      await ProductsDB.addProducts(allProducts.data.results);
      productDetails = await ProductsDB.getProduct(productId);
    }
    const total = value * unitPrice;
    const discount = value * unitDiscount;
    const totalAfterDiscount = total - discount;
    if (typeof productDetails == 'object' && productDetails.vat != undefined) {
      const vat = productDetails.vat;
      const vatAmt = totalAfterDiscount * (vat / 100);
      form.setFieldValue(['lines', index, 'totalReturn'], totalAfterDiscount + vatAmt);
      form.setFieldValue(['lines', index, 'vat'], vatAmt);
      form.setFieldValue(['lines', index, 'discount'], discount);
    } else {
      form.setFieldValue(['lines', index, 'totalReturn'], totalAfterDiscount);
      form.setFieldValue(['lines', index, 'vat'], 0);
      form.setFieldValue(['lines', index, 'discount'], discount);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Product',
      dataIndex: 'productName'
    },
    {
      title: 'Unit',
      dataIndex: 'unitName'
    },
    {
      title: 'HS Code',
      dataIndex: 'hsCode',
      render: (text: string) => <div>{text || 'N/A'}</div>
    },
    {
      title: 'Rate',
      dataIndex: 'unitPrice'
    },
    {
      title: 'Quantity',
      dataIndex: 'totalQuantity'
    },
    {
      title: 'Returned Quantity',
      dataIndex: 'alreadyreturnQuantity',
      render: (text: number) => {
        return <div>{numberDecimalFormatter(text)}</div>;
      }
    },
    {
      title: 'Return',
      dataIndex: 'totalQuantity',
      render: (value: number, row: any, index: number) => {
        form.setFieldValue(['lines', index], row);
        const precision = row.allowQtyDecimal ? 3 : 0;
        return (
          <Form.Item
            name={['lines', index, 'quantity']}
            key={row.id}
            rules={[
              { required: false, message: 'Please add quantity!' },
              () => ({
                validator(_: any, value: any) {
                  if (value == 0) {
                    return Promise.resolve();
                  }
                  const maxValue = numberDecimalFormatter(
                    row.totalQuantity - row.alreadyreturnQuantity,
                    true
                  );
                  if (value > maxValue) {
                    return Promise.reject(`Quantity exceeded!`);
                  }
                  const lotId = form.getFieldValue(['lines', index, 'lotId']);
                  if (!lotId) {
                    return Promise.reject('Select Lot');
                  } else {
                    const currLot = totalLots.find((curr: any) => curr.id == lotId);
                    if (!currLot) return Promise.reject('Select Lot');
                    const unitId = form.getFieldValue(['lines', index, 'unitId']);
                    const unitInfo: any = allLocalUnits.find((val: any) => unitId == val.id);
                    const availableQuantity = currLot.qtyAvailable / unitInfo?.baseUnitMultiplier;
                    if (availableQuantity < value) {
                      return Promise.reject(`Lot has ${availableQuantity} only!`);
                    }
                  }

                  if ('productId' in form.getFieldValue(['lines', index])) {
                    form.setFieldValue(['lines', index, 'quantity'], value);
                  } else {
                    form.setFieldValue(['lines', index], { ...row, quantity: value });
                  }
                  return Promise.resolve();
                }
              })
            ]}>
            <InputNumber
              controls={false}
              max={value}
              min={0}
              precision={precision}
              defaultValue={0}
              onChange={async (val) => {
                if (typeof val === 'number')
                  await calculateTotalReturn(
                    row.productId,
                    val,
                    index,
                    row.unitPrice,
                    row.unitDiscount
                  );
              }}
            />
          </Form.Item>
        );
      }
    },
    {
      title: 'Lots',
      dataIndex: 'selectLots',
      render: (value: number, row: any, index: number) => {
        return (
          <>
            <Form.Item
              name={['lines', index, 'lotId']}
              key={row.id}
              rules={[
                {
                  required: true,
                  message: 'Select Lot!'
                }
              ]}>
              <Select
                placeholder="Select a Lot!"
                dropdownMatchSelectWidth={false}
                allowClear
                onChange={(val: number) => {
                  form.setFieldValue(['lines', index, 'quantity'], 0);
                  form.setFieldValue(['lines', index, 'lotId'], val);
                }}>
                {FilterLot(row.productId, row.locationId, row.unitId, index)}
              </Select>
            </Form.Item>
          </>
        );
      }
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      render: (value: number, row: any, index: number) => {
        return (
          <Form.Item name={['lines', index, 'discount']} key={row.id}>
            <InputNumber controls={false} min={0} defaultValue={0} disabled />
          </Form.Item>
        );
      }
    },
    {
      title: 'Total Return',
      dataIndex: 'totalQuantity',
      render: (value: number, row: any, index: number) => {
        return (
          <Form.Item name={['lines', index, 'totalReturn']} key={row.id}>
            <InputNumber controls={false} min={0} defaultValue={0} disabled />
          </Form.Item>
        );
      }
    }
  ];

  const purchaseReturnMutation = useMutation(create_purchase_return_mutation, {
    onSuccess: () => {
      message.success('Purchase return filed');
    },
    onError: async (data: any) => {
      message.error(data.response.data.message);
    }
  });
  const handleReturn = async () => {
    try {
      await form
        .validateFields()
        .then(async () => {
          setIsLoading(true);
          const formData: any[] = form.getFieldValue('lines');
          // console.log(formData);
          const value = {
            purchaseId: purchaseDetails?.purchase.id,
            date: JSON.stringify(new Date()).slice(1, -1),
            lines: formData.filter((item) => item.quantity > 0),
            note: form.getFieldValue('note')
          };
          if (value.lines.length === 0) {
            message.error('Please select at least one product to return');
            setIsLoading(false);
            return;
          }
          for (let ind = 0; ind < value.lines.length; ind++) {
            if (!value.lines[ind].lotId) {
              message.error('Please select lot!');
              setIsLoading(false);
              return;
            }
          }
          refetch();
          setIsLoading(false);
          await purchaseReturnMutation.mutateAsync(value as ICreatePurchaseReturnRequest);
        })
        .catch(() => message.error('Empty fields found!'));
    } catch (err: any) {
      message.error(err.response.data.message);
    }
  };

  useEffect(() => {
    initializeUnits();
  }, []);

  const initializeUnits = async () => {
    setAllLocalUnits((await UnitsDB.getAllUnits()) as IUnits[]);
  };

  return (
    <Spin spinning={isLoading}>
      <AppContent breadcrumbItems={breadcrumbItems}>
        <PageHeader
          title="Return Information"
          style={{
            padding: '8px 0px'
          }}
        />
        {purchaseDetails ? (
          <div className="card grid grid-cols-2 gap-5">
            <Text>Financial Reference : {purchaseDetails.purchase.financialReference}</Text>
            <Text>Vendor : {purchaseDetails.purchase.vendorName}</Text>
            <Text>Location : {purchaseDetails.purchase.locationId}</Text>
            <Text>Total : {purchaseDetails.purchase.totalAmount}</Text>
          </div>
        ) : (
          <></>
        )}
        <Form form={form} component={false}>
          <Table
            bordered
            dataSource={data}
            columns={columns}
            pagination={false}
            scroll={{ y: 600, x: 1200 }}
          />
          <Divider />
          <Form.Item
            label="Return Reason"
            name="note"
            rules={[
              {
                required: true,
                message: 'Please input return message'
              }
            ]}>
            <Input.TextArea placeholder="Write a reason..." />
          </Form.Item>
          <div className="flex justify-end mt-5">
            <DebounceButton
              type="primary"
              onClick={handleReturn}
              disabled={editingKey !== undefined || !hasRule || isAccountArchived}>
              Submit
            </DebounceButton>
          </div>
        </Form>
      </AppContent>
    </Spin>
  );
};

export default PurchaseReturnV2;
